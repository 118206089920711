<template>
    <div id="app">
        <Loading v-if="!ready"></Loading>
        <div v-show="ready">
            <router-view />
        </div>
    </div>
</template>

<script>
import Loading from "./components/Loading.vue";

export default {
    name: "App",
    components: {
        Loading,
    },
    data() {
        return {
            ready: false,
        };
    },
    mounted() {
        let vm = this;
        this.$root.$on("ready", function () {
            vm.ready = true;
        });
    },
};
</script>

<style lang="scss">
@import "./styles/app.scss";
</style>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>
