<template>
  <div class="dropdown" v-bind:class="{ 'is-active': isOpen }">
    <div class="dropdown-trigger">
      <button
        class="button"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        v-on:click="isOpen = true"
      >
        <span>{{ leagueSelected.value }}</span>
        <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a
          v-for="option in leagues"
          :key="option.key"
          class="dropdown-item"
          v-on:click="selectOption(option)"
        >
          {{ option.value }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LeagueSelectorDropdown',
  props: {
    leagues: {
      type: Array,
      required: true,
    },
    leagueSelected: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,      
    };
  },
  methods: {
    selectOption(option) {
      //this.selectedOption = option;
      this.isOpen = false;
      this.$emit("league-selected", option);
      if(option.callback) {
        option.callback();
      }
    },
  },
  mounted() {
    this.$el.addEventListener("mouseleave", () => {
      this.isOpen = false;
    });
  },
};
</script>
