<template>
  <header class="container">
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item">
          <img src="../assets/hf_logo.png" />
        </a>
        <div class="my-3 has-text-left">
          <h3 class="title">Hike &amp; Fly</h3>
          <h3 class="subtitle">Berguedà {{  year  }}</h3>
        </div>
        <navbar-burger-botton
          v-bind:class="{ 'is-active': isOpen }"
          v-on:click="isOpen = !isOpen"
        ></navbar-burger-botton>
      </div>
      <div v-if="withLinks" class="navbar-menu" v-bind:class="{ 'is-active': isOpen }">
        <div class="navbar-end">
          <router-link to="/registre" class="navbar-item">Inscripcions 2025</router-link>
          <a href="#" v-on:click="$refs.combos.open()" class="navbar-item"
            >Calculadora Combos</a
          >
          <PreviousEditionsDropdown />
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link"> Xarxes socials </a>

            <div class="navbar-dropdown">
              <a href="https://www.strava.com/clubs/999879" class="navbar-item"
                ><span class="la la-strava">Strava</span></a
              >
              <a href="https://www.instagram.com/h_f_bergueda/" class="navbar-item"
                ><span class="la la-instagram">Instagram</span></a
              >
              <a
                href="https://ca.wikiloc.com/wikiloc/user.do?id=9888972"
                class="navbar-item"
                ><span class="la la-instagram">Wikiloc</span></a
              >
            </div>
          </div>
          <a
            href="https://hikeandfly.puntxat.cat/admin/storage/hf_bergueda_normativa_2024.pdf"
            class="navbar-item"
            >Normativa</a
          >
          <a href="#" v-on:click="$refs.sponsors.open()" class="navbar-item"
            >Col·laboradors</a
          >
        </div>
      </div>
    </nav>
    <Sponsors ref="sponsors"></Sponsors>
    <ComboCalculator ref="combos"></ComboCalculator>
  </header>
</template>

<script>
import NavbarBurgerBotton from "./NavbarBurgerBotton.vue";
import PreviousEditionsDropdown from "./PreviousEditionsDropdown.vue";
export default {
  name: "Header",
  components: {
    Sponsors: () => import(/* webpackChunkName: "sponsors-chunk" */ "./Sponsors.vue"),
    ComboCalculator: () =>
      import(/* webpackChunkName: "combo-calculator-chunk" */ "./ComboCalculator.vue"),
    PreviousEditionsDropdown,
    NavbarBurgerBotton,
  },
  props: {
    year: {
      type: String,
      required: false,
      default: "2024",
    },
    withLinks: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
