<template>
    <div class="has-text-centered" style="position: relative">
        <div
            class="table-container mb-0"
            style="overflow-y: auto; max-height: calc(100vh - 90px)"
            v-if="data"
        >
            <table
                v-if="data"
                class="table has-sticky-header has-sticky-column is-fullwidth is-striped"
                v-bind:class="{'is-showing-all-hikes': showAllHikes}"
            >
                <thead>
                    <tr>

                        <th v-if="hasFilterByLeague" class="separator" colspan="2" style="z-index: 10">
                            <LeagueSelectorDropdown 
                                v-bind:leagues="leagues" 
                                v-bind:leagueSelected="leagueSelected" 
                                v-on:league-selected="setLeagueFilter" 
                            />
                        </th>
                        <template v-else>
                            <th class="w-75">Pos</th>
                            <th class="separator" v-bind:class="{'l75': showPositionVariations}">Nom</th>
                        </template>

                        <template v-for="zone in data.zones">
                            <template v-for="peak in zone.peaks">
                                <th class="is-hidden-mobile" v-bind:key="'peak_' + peak.id">
                                    {{ peak.name }}
                                </th>
                            </template>
                            <th
                                class="separator is-hidden-mobile"
                                v-bind:key="'zone_' + zone.id"
                            >
                                {{ zone.name }}
                            </th>
                        </template>
                        <th class="separator has-text-centered has-cursor-pointer"
                            style="white-space: nowrap">
                            <div v-on:click="setOrdenationCriteria('hikes')">
                                Ascensions
                                <span
                                    class="icon"
                                    v-bind:class="{
                                        'has-text-grey-light':
                                            orderedBy != 'hikes',
                                    }"
                                ><i class="fa-solid fa-caret-down"></i></span>
                            </div>
                            <a v-if="!showAllHikes" class="is-size-7 is-hidden-tablet" v-on:click="setShowAllHikes(true)">veure tot</a>
                        </th>
                        <th v-if="showCombosScore" class="separator">Viviflies</th>
                        <th v-if="showCombosScore" class="separator">Combos</th>
                        <th
                            class="separator"
                            v-on:click="setOrdenationCriteria('integral')"
                            v-bind:class="{
                                'has-cursor-pointer': rankingHasSomeIntegral,
                            }"
                            style="white-space: nowrap"
                        >
                            Integrals
                            <span
                                class="icon"
                                v-if="rankingHasSomeIntegral"
                                v-bind:class="{
                                    'has-text-grey-light': orderedBy != 'integral',
                                }"
                            >
                                <i class="fa-solid fa-caret-down"></i>
                            </span>
                        </th>
                        <th
                            class="has-cursor-pointer"
                            v-on:click="setOrdenationCriteria('score')"
                            style="white-space: nowrap"
                        >
                            Punts
                            <span
                                class="icon"
                                v-bind:class="{
                                    'has-text-grey-light':
                                        orderedBy != 'score',
                                }"
                            >
                                <i class="fa-solid fa-caret-down"></i>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <Pilot
                        v-for="pilot in orderedPilots"
                        v-on:click="openPilotDetails"
                        v-bind:key="pilot.id"
                        v-bind:value="pilot"
                        v-bind:zones="data.zones"
                        v-bind:viviflies="data.viviflies"
                        v-bind:showAllHikes="showAllHikes"
                        v-bind:position="pilot.position"
                        v-bind:showIntegralTime="true"
                        v-bind:showCombosScore="showCombosScore"
                        v-bind:showPositionVariations="showPositionVariations"
                        v-bind:showGenderIcon="!leagueSelected.key"
                    ></Pilot>
                </tbody>
            </table>
            <footer class="footer">
                <small v-if="data.last_update">Última actualització {{ data.last_update }}</small>
                <div class="is-flex">
                    <img
                    src="../assets/sponsors/salewa.png"
                    class="image mx-auto my-3"
                    title="Tingladu Puntxat"
                    />
                    <img
                    src="../assets/puntxat.png"
                    class="image mx-auto my-3"
                    title="Tingladu Puntxat"
                    />
                    <img
                    src="../assets/sponsors/niviuk.png"
                    class="image mx-auto my-3"
                    title="Tingladu Puntxat"
                    />
                </div>
            </footer>
        </div>
        <PilotDetails
            v-bind:pilotId="pilotDetailsId"
            v-bind:isOpen="pilotDetailsId != null"
            v-on:close="closePilotDetails"
        />
    </div>
</template>

<script>
import Pilot from "./Pilot.vue";
import PilotDetails from "./PilotDetails.vue";
import LeagueSelectorDropdown from "./LeagueSelectorDropdown.vue";

export default {
    name: "Ranking",
    props: {
        showCombosScore: {
            type: Boolean,
            default: true,
        },
        showPositionVariations: {
            type: Boolean,
            default: true,
        },
        hasFilterByLeague: {
            type: Boolean,
            default: false,
        },
        leagueSelected: {
            type: Object,
        },
        leagues: {
            type: Array
        },
    },
    components: {
        Pilot,
        PilotDetails,
        LeagueSelectorDropdown
    },
    data() {
        return {
            data: null,
            pilotDetailsId: null,
            orderedBy: "score",            
            showAllHikes: false,
        };
    },
    computed: {
        filteredPilots() {
            if(!this.hasFilterByLeague || !this.leagueSelected.key)
                return this.data.pilots;

            return this.data.pilots.filter((pilot) => pilot.gender == this.leagueSelected.key);
        },
        orderedPilots() {
            if (this.orderedBy == "integral")
                return this.orderedPilotsByIntegral();
            if (this.orderedBy == "hikes")
                return this.orderedPilotsByHikes();

            return this.orderedPilotsByScore();
        },
        rankingHasSomeIntegral() {
            for (var i = 0; i < this.filteredPilots.length; i++) {
                if (this.filteredPilots[i].integral_score > 0) return true;
            }

            return false;
        },
    },
    methods: {
        setShowAllHikes(value) {
            this.showAllHikes = value;
        },
        loadData(data) {
            this.data = data;
        },
        openPilotDetails(id) {
            this.pilotDetailsId = id;
        },
        closePilotDetails() {
            this.pilotDetailsId = null;
        },
        setOrdenationCriteria(criteria) {
            this.orderedBy = criteria;
        },
        setLeagueFilter(filter) {
            this.$emit("league-selected", filter);
        },
        /**
         * We will calculate position ordinal on runtime and
         * if point are equal, position is nos incremented
         * (to get the effect of a 5th position may be occupied for
         * 3 pilots)
         */
        orderedPilotsByScore() {
            var isDrawCallback = function (pilots, index) {
                return (
                    index > 0 &&
                    pilots[index - 1].score == pilots[index].score &&
                    pilots[index - 1].integral_time ==
                        pilots[index].integral_time
                );
            };

            return this.filteredPilots.recalculatePosition(isDrawCallback);
        },
        orderedPilotsByHikes() {
            var isDrawCallback = function(pilots, index) {
                return (
                    index > 0 &&
                    pilots[index - 1].hikes_score == pilots[index].hikes_score
                );
            };

            return [...this.filteredPilots]
                .sort((a, b) => {
                    if (a.hikes_score < b.hikes_score)
                        return 1;
                    if(a.hikes_score > b.hikes_score)
                        return -1;
                    return 0;
                })
                .recalculatePosition(isDrawCallback);
        },
        orderedPilotsByIntegral() {
            var isDrawCallback = function (pilots, index) {
                return (
                    index > 0 &&
                    pilots[index - 1].integral_score ==
                        pilots[index].integral_score &&
                    pilots[index - 1].integral_time ==
                        pilots[index].integral_time
                );
            };

            return [...this.filteredPilots]
                .sort((a, b) => {
                    if (
                        a.integral_score < b.integral_score ||
                        (a.integral_score == b.integral_score &&
                            a.integral_time > b.integral_time)
                    )
                        return 1;

                    if (
                        a.integral_score > b.integral_score ||
                        (a.integral_score == b.integral_score &&
                            a.integral_time < b.integral_time)
                    )
                        return -1;

                    return 0;
                })
                .recalculatePosition(isDrawCallback);
        },
    },
    mounted() {
        Array.prototype.recalculatePosition = function (isDrawCallback) {
            this.forEach((pilot, index) => {
                pilot.position = index + 1;
                if (isDrawCallback(this, index)) {
                    // we will repeat position number if there is a draw
                    pilot.position = this[index - 1].position;
                }
            });

            return this;
        };
    },
};
</script>

<style lang="scss">
@import "bulma/sass/utilities/mixins.sass";

th {
    vertical-align: bottom !important;
}
.separator {
    border-right-width: 2px !important;
}
.container-overflow {
    height: calc(100vh - 90px);
    overflow: scroll;
}

.table.has-sticky-column thead th:nth-child(2),
.table.has-sticky-column tbody td:nth-child(2) {
    background-color: white;
    position: sticky;
    left: 75px;
    z-index: 1;
}

.table.has-sticky-column thead th:nth-child(2) {
    z-index: 6;
}

.table.has-sticky-column.is-striped
    tbody
    tr:not(.is-selected):nth-child(2n)
    td {
    background: #fafafa;
}

.w-75 {
    min-width: 75px !important;
}

.footer {
    position: sticky;
    left: 0;

    .image {
        height: 50px;
    }

    @include desktop {
        .image {
            height: 100px;
        }
    }
}

.is-showing-all-hikes {
    th.is-hidden-mobile {
        display: table-cell !important;
    }
}
</style>
