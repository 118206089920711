<template>
    <div>
        <Header :year="2024"></Header>
        <Ranking
            ref="ranking"
            v-bind:hasFilterByLeague="true"
            v-bind:leagues="$options.leagues"
            v-bind:leagueSelected="leagueSelected"
            v-on:league-selected="setLeagueSelected"
        ></Ranking>
    </div>
</template>

<script>
import Header from "../components/Header.vue";
import Ranking from "../components/Ranking.vue";
import results from "./ranking-2024.json";

export default {
    name: "Edition2024",
    RESULTS2024: results,
    components: {
        Header,
        Ranking,
    },
    leagues: [
        {
            key: null,
            value: "Classificació general",
        },
        {
            key: "F",
            value: "Classificació femenina",
        },
    ],
    data() {
        return {
            leagueSelected: {
                key: null,
                value: "Classificació general",
            },
        };
    },
    methods: {
        setLeagueSelected(league) {            
            this.leagueSelected = league;
        },
    },
    mounted() {
        //this.$refs.ranking.loadData(this.$options.RESULTS2024.data);
        //this.$root.$emit("ready");
        this.$http.get("/api/ranking").then((response) => {
            this.$refs["ranking"].loadData(response.data.data);
            let vm = this;
            setTimeout(function () {
                vm.$root.$emit("ready");
            }, 50);
        });
    },
};
</script>
