var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"has-text-centered",staticStyle:{"position":"relative"}},[(_vm.data)?_c('div',{staticClass:"table-container mb-0",staticStyle:{"overflow-y":"auto","max-height":"calc(100vh - 90px)"}},[(_vm.data)?_c('table',{staticClass:"table has-sticky-header has-sticky-column is-fullwidth is-striped",class:{'is-showing-all-hikes': _vm.showAllHikes}},[_c('thead',[_c('tr',[(_vm.hasFilterByLeague)?_c('th',{staticClass:"separator",staticStyle:{"z-index":"10"},attrs:{"colspan":"2"}},[_c('LeagueSelectorDropdown',{attrs:{"leagues":_vm.leagues,"leagueSelected":_vm.leagueSelected},on:{"league-selected":_vm.setLeagueFilter}})],1):[_c('th',{staticClass:"w-75"},[_vm._v("Pos")]),_c('th',{staticClass:"separator",class:{'l75': _vm.showPositionVariations}},[_vm._v("Nom")])],_vm._l((_vm.data.zones),function(zone){return [_vm._l((zone.peaks),function(peak){return [_c('th',{key:'peak_' + peak.id,staticClass:"is-hidden-mobile"},[_vm._v(" "+_vm._s(peak.name)+" ")])]}),_c('th',{key:'zone_' + zone.id,staticClass:"separator is-hidden-mobile"},[_vm._v(" "+_vm._s(zone.name)+" ")])]}),_c('th',{staticClass:"separator has-text-centered has-cursor-pointer",staticStyle:{"white-space":"nowrap"}},[_c('div',{on:{"click":function($event){return _vm.setOrdenationCriteria('hikes')}}},[_vm._v(" Ascensions "),_c('span',{staticClass:"icon",class:{
                                    'has-text-grey-light':
                                        _vm.orderedBy != 'hikes',
                                }},[_c('i',{staticClass:"fa-solid fa-caret-down"})])]),(!_vm.showAllHikes)?_c('a',{staticClass:"is-size-7 is-hidden-tablet",on:{"click":function($event){return _vm.setShowAllHikes(true)}}},[_vm._v("veure tot")]):_vm._e()]),(_vm.showCombosScore)?_c('th',{staticClass:"separator"},[_vm._v("Viviflies")]):_vm._e(),(_vm.showCombosScore)?_c('th',{staticClass:"separator"},[_vm._v("Combos")]):_vm._e(),_c('th',{staticClass:"separator",class:{
                            'has-cursor-pointer': _vm.rankingHasSomeIntegral,
                        },staticStyle:{"white-space":"nowrap"},on:{"click":function($event){return _vm.setOrdenationCriteria('integral')}}},[_vm._v(" Integrals "),(_vm.rankingHasSomeIntegral)?_c('span',{staticClass:"icon",class:{
                                'has-text-grey-light': _vm.orderedBy != 'integral',
                            }},[_c('i',{staticClass:"fa-solid fa-caret-down"})]):_vm._e()]),_c('th',{staticClass:"has-cursor-pointer",staticStyle:{"white-space":"nowrap"},on:{"click":function($event){return _vm.setOrdenationCriteria('score')}}},[_vm._v(" Punts "),_c('span',{staticClass:"icon",class:{
                                'has-text-grey-light':
                                    _vm.orderedBy != 'score',
                            }},[_c('i',{staticClass:"fa-solid fa-caret-down"})])])],2)]),_c('tbody',_vm._l((_vm.orderedPilots),function(pilot){return _c('Pilot',{key:pilot.id,attrs:{"value":pilot,"zones":_vm.data.zones,"viviflies":_vm.data.viviflies,"showAllHikes":_vm.showAllHikes,"position":pilot.position,"showIntegralTime":true,"showCombosScore":_vm.showCombosScore,"showPositionVariations":_vm.showPositionVariations,"showGenderIcon":!_vm.leagueSelected.key},on:{"click":_vm.openPilotDetails}})}),1)]):_vm._e(),_c('footer',{staticClass:"footer"},[(_vm.data.last_update)?_c('small',[_vm._v("Última actualització "+_vm._s(_vm.data.last_update))]):_vm._e(),_vm._m(0)])]):_vm._e(),_c('PilotDetails',{attrs:{"pilotId":_vm.pilotDetailsId,"isOpen":_vm.pilotDetailsId != null},on:{"close":_vm.closePilotDetails}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-flex"},[_c('img',{staticClass:"image mx-auto my-3",attrs:{"src":require("../assets/sponsors/salewa.png"),"title":"Tingladu Puntxat"}}),_c('img',{staticClass:"image mx-auto my-3",attrs:{"src":require("../assets/puntxat.png"),"title":"Tingladu Puntxat"}}),_c('img',{staticClass:"image mx-auto my-3",attrs:{"src":require("../assets/sponsors/niviuk.png"),"title":"Tingladu Puntxat"}})])
}]

export { render, staticRenderFns }